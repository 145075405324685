// Import CSS From Dependencies her so PurgeCSS handles them correctly

// Import normaize css
import "./node_modules/normalize.css/normalize.css"
// Import AOS
import "./node_modules/aos/dist/aos.css"
// Import Workbench Modules, unused CSS should be purged by the Gatsby Build
import "./node_modules/wb2/dist/css/globals.css"

import "./src/styles/tailwind.css"

import "./src/styles/layout.css"
